import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

//assets

//styles
import mediaQuery from "src/assets/styles/mediaQuery.js"
import distances from "src/assets/styles/distances.js"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"

//components

const StyledHamburger = styled.div`
  display: none;

  position: absolute;
  top: 27rem;
  right: ${distances.gap}rem;
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  cursor: pointer;
  z-index: 99;

  :hover {
    .line-2:before {
      width: 20rem;
      transition: width 0.4s ease;
    }
  }

  .line-1,
  .line-2 {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: transform 0.4s ease,
      background 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .line-1:before,
  .line-2:before {
    content: "";
    display: block;
    width: 20rem;
    height: 1rem;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .line-2:before {
    width: 14rem;
    right: 0;
    left: auto;
    transition: transform 0.4s ease, width 0.4s ease;
  }

  .line-1 {
    transform: translateY(-4rem);
  }

  .line-2 {
    transform: translateY(4rem);
  }

  &.open .line-1:before,
  &.open .line-2:before {
    outline: 0 solid transparent;
  }

  &.open .line-1 {
    transform: rotate(225deg);
  }

  &.open .line-2 {
    transform: rotate(-45deg) translateY(0px);
  }

  &.open .line-2:before {
    width: 20rem;
    left: 0;
    right: 0;
    transition: width 0.4s ease;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`

const Hamburger = ({ onClick, open }) => {
  return (
    <StyledHamburger
      onClick={onClick}
      className={open ? "open" : ""}
      aria-label="menu button"
    >
      <span className="line-1"></span>
      <span className="line-2"></span>
    </StyledHamburger>
  )
}

export default Hamburger
