import React, { useState } from "react";
import styled from "styled-components";
import slugify from "slugify";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";

//assets
import logo from "src/assets/svg/Logo.svg";
import Arrow from "src/assets/svg/ExCap-arrow.inline.svg";

//styles
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";

//components
import CardTopBottom from "src/components/global/cards/CardTopBottom.js";
import CardBottom from "src/components/global/cards/CardBottom.js";
import Hamburger from "src/components/global/nav/Hamburger.js";

const Wrapper = styled.div`
  position: fixed;
  height: calc(100vh - ${distances.pageMarginBig * 2}rem);
  top: ${distances.pageMarginBig}rem;
  left: ${distances.pageMarginBig}rem;
  height: ${(props) =>
    `calc(${props.height100}rem - ${distances.pageMarginBig * 2}rem)`};

  width: 190rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  box-sizing: border-box;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${distances.gap * 2}rem);
    overflow: hidden;
    border-bottom: 1rem solid ${colors.greenDark};
    transition: all 0.5s;

    &.close {
      height: 74rem;
      transition: all 0.5s;
    }
  }
`;

const TopNav = styled(CardTopBottom)`
  background: ${colors.green};
  width: calc(100% - ${distances.gap * 2}rem);
  padding: ${distances.gap}rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const BottomNav = styled(CardBottom)`
  background: ${colors.green};
  width: calc(100% - ${distances.gap * 2}rem);
  padding: ${distances.gap}rem;
`;

const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;
`;

const LinkLogo = styled(Link)`
  display: inline;
  width: 120rem;
`;

const Logo = styled.img`
  margin-bottom: 30rem;
  width: 120rem;
`;

const StyledArrow = styled(Arrow)`
  width: 20rem;
  margin-left: -30rem;
  margin-right: 10rem;
  transition: all 0.5s;
`;

const StyledLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  z-index: 999;
  text-decoration: none;
  padding: 7rem 0;
  overflow: hidden;
  white-space: nowrap;
  span {
    display: inline-block;
    white-space: nowrap;
  }
  span::first-letter {
    text-transform: uppercase;
  }
  &::first-letter {
    text-transform: uppercase;
  }

  &:first-of-type {
    padding-top: 0;
  }
  &:hover {
    ${StyledArrow} {
      margin-left: 0rem;
      transition: all 0.5s;
    }
  }
  &.active {
    ${StyledArrow} {
      margin-left: 0rem;
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 10rem 0;
    &:first-of-type {
      padding-top: 10rem;
    }
  }
`;

const InfoTitle = styled.span`
  display: block;
  font-size: ${typographySizes.xs}rem;
  color: ${colors.orange};
  margin-bottom: 5rem;
  &:nth-of-type(2) {
    margin-top: 20rem;
  }
`;

const InfoLink = styled.a`
  font-size: ${typographySizes.xs}rem;
  color: ${colors.white};
  text-decoration: none;
`;

const Nav = ({
  aboutPage,

  portfolioPage,
  globalReachPage,
  contactPage,
  servicesPage,
  careerPage,
  newsPage,
  langPageName,
  lang,
  catalog,
  contactInformation,
  home,
}) => {
  const [navOpen, setNavOpen] = useState(false);
  const height100 = use100vh();

  let slugLangPageName = false;

  if (langPageName) {
    slugLangPageName = slugify(langPageName.toString(), {
      lower: true,
      strict: true,
    });
  }

  const slugPageNameAbout = slugify(aboutPage, {
    lower: true,
    strict: true,
  });
  const slugPageNamePortfolio = slugify(portfolioPage, {
    lower: true,
    strict: true,
  });
  const slugPageNameServices = slugify(servicesPage, {
    lower: true,
    strict: true,
  });
  const slugPageNameContact = slugify(contactPage, {
    lower: true,
    strict: true,
  });
  const slugPageNameGlobalReachPage = slugify(globalReachPage, {
    lower: true,
    strict: true,
  });

  const slugPageNameCareere = slugify(careerPage, {
    lower: true,
    strict: true,
  });

  const slugNewsPage = slugify(newsPage, {
    lower: true,
    strict: true,
  });
  const handleHamburgerClick = () => {
    setNavOpen(!navOpen);
  };
  return (
    <Wrapper height100={height100} className={navOpen ? "" : "close"}>
      <TopNav>
        <NavigationWrapper>
          <LinkLogo to={`${lang === "en" ? `/en/home` : `/`}`}>
            <Logo src={logo} />
          </LinkLogo>
          <Hamburger onClick={handleHamburgerClick} open={navOpen} />
          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameAbout}`}
          >
            <StyledArrow />
            <span>{aboutPage}</span>
          </StyledLink>
          <StyledLink
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameAbout}#team`}
          >
            <StyledArrow />
            <span>{lang === "en" ? "our team" : "Nasz Zespół"}</span>
          </StyledLink>
          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameServices}`}
          >
            <StyledArrow />
            <span>{servicesPage}</span>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNamePortfolio}`}
          >
            <StyledArrow />
            <span>{portfolioPage}</span>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameGlobalReachPage}`}
          >
            <StyledArrow />
            <span>{globalReachPage}</span>
          </StyledLink>
          <StyledLink
            activeClassName={"active"}
            partiallyActive={true}
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugNewsPage}`}
          >
            <StyledArrow />
            <span>{newsPage}</span>
          </StyledLink>
          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameContact}`}
          >
            <StyledArrow />
            <span>{contactPage}</span>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            onClick={handleHamburgerClick}
            to={`/${lang === "en" ? "en/" : ""}${slugPageNameCareere}`}
          >
            <StyledArrow />
            <span>{careerPage}</span>
          </StyledLink>
        </NavigationWrapper>

        {(slugLangPageName || home || catalog) && (
          <StyledLink
            activeClassName="active"
            className="lang"
            to={`${
              lang === "en"
                ? `/${catalog ? `${catalog}/` : ""}${slugLangPageName}`
                : `/en/${catalog ? `${catalog}/` : ""}${slugLangPageName}`
            }`}
          >
            {lang === "en" ? "PL" : "EN"}
          </StyledLink>
        )}
      </TopNav>
      <BottomNav>
        <InfoTitle> {lang === "pl" ? "Adres" : "Address"}</InfoTitle>
        <InfoLink href={contactInformation.Maps_link} target="_blank">
          {contactInformation.Company_name} <br /> {contactInformation.Street}{" "}
          <br /> {contactInformation.City}
        </InfoLink>
        <InfoTitle> {lang === "pl" ? "Kontakt" : "Contact"}</InfoTitle>
        <InfoLink href={`mailto:${contactInformation.Email}`}>
          {contactInformation.Email}
        </InfoLink>
      </BottomNav>

      {/* <ContextConsumer>{({ data, set }) => <></>}</ContextConsumer> */}
    </Wrapper>
  );
};

export default Nav;
