import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

//assets
import Pattern from "src/assets/svg/pattern/single-pattern.inline.svg";

//styles
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";

//components
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";

import CardMain from "src/components/global/cards/CardMain.js";
import Body from "src/components/global/typo/Body.js";

const Wrapper = styled.footer`
  position: relative;
  margin-bottom: ${distances.gap}rem;
  margin-top: ${distances.gap * 3}rem;
  z-index: 150;
`;

const StyledCard = styled(CardMain)`
  background-color: ${colors.green};
  padding: ${distances.gap}rem;
  width: calc(100% - ${distances.gap * 2}rem);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${distances.gap}rem;
  ${Body} {
    font-size: ${typographySizes.xs}rem;
  }
  a {
    text-decoration: none;
    transition: all 0.5s;
    font-size: ${typographySizes.xs}rem;

    &:hover {
      color: ${colors.orange};
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    .ExCap {
      margin-bottom: ${distances.gap}rem;
      width: 230rem;
    }
  }
`;

const Footer = ({ title, img, lang }) => (
  <MainWrapper>
    <Wrapper>
      <StyledCard>
        <ContentWrapper>
          <Body className="ExCap">
            {lang === "pl"
              ? `Excalibur Capital © ${new Date().getFullYear()}  Wszelkie prawa zastrzeżone`
              : `Excalibur Capital © ${new Date().getFullYear()} All rights reserved.`}
          </Body>
          <Body>
            well designed by{" "}
            <a href="https://we3studio.pl/" target="_blank">
              we3studio
            </a>{" "}
          </Body>
        </ContentWrapper>
        <Line />
      </StyledCard>
    </Wrapper>
  </MainWrapper>
);

export default Footer;
