import styled from "styled-components"
import distances from "src/assets/styles/distances.js"
//styles
import colors from "src/assets/styles/colors.js"
const CardBottom = styled.div`
  clip-path: polygon(
    8rem 0%,
    calc(100% - 8rem) 0%,
    100% 8rem,
    100% 100%,
    0 100%,
    0% 8rem
  );
  border: solid 1rem ${colors.greenDark};
  margin-top: 3rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 7rem;
    height: 1rem;
    width: 10rem;
    background-color: ${colors.greenDark};
    transform-origin: top right;
    transform: rotate(-135deg);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -4rem;
    height: 1rem;
    width: 10rem;
    background-color: ${colors.greenDark};
    transform-origin: top right;
    transform: rotate(-45deg);
  }
`

export default CardBottom
