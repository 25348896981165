import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { ContextProviderComponent } from "src/layouts/Context.js";
import GlobalStyle from "src/assets/styles/globalStyles.js";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import Footer from "src/components/global/Footer.js";

// import Cookies from "src/components/global/Cookies.js"

const HomePage = ({ data, children, pageContext }) => {
  useEffect(() => {
    const lettersToReplace = [
      "a",
      "i",
      "o",
      "u",
      "w",
      "z",
      "A",
      "I",
      "O",
      "U",
      "W",
      "Z",
    ];
    const arrayLength = lettersToReplace.length;
    const elements = document.querySelectorAll(
      " h2:not(.intro-text), h3:not(.letter), h4:not(.letter), h5:not(.letter), h6:not(.letter), p:not(.intro-text)"
    );

    elements.forEach((element) => {
      let textReplace = element.innerHTML;

      for (let i = 0; i < arrayLength; i++) {
        const textSplit = textReplace.split(` ${lettersToReplace[i]} `);
        textReplace = textSplit.join(` ${lettersToReplace[i]}&nbsp;`);
      }

      element.innerHTML = textReplace;
    });
  }, [{}]);
  return (
    <>
      <ContextProviderComponent>
        <Seo
          title={pageContext.seoTitle}
          description={pageContext.seoDescription}
          image={pageContext.seoImg}
        />
        <GlobalStyle />
        <Nav
          lang={pageContext.locale}
          langPageName={pageContext.langPageName}
          aboutPage={data.strapiPageAbout.Page_name}
          portfolioPage={data.strapiPagePortfolio.Page_name}
          servicesPage={data.strapiPageService.Page_name}
          contactPage={data.strapiPageContact.Page_name}
          globalReachPage={data.strapiPageGlobalReach.Page_name}
          contactInformation={data.strapiContactInformation}
          careerPage={data.strapiPageCareer.Page_name}
          newsPage={data.strapiPageNews.Page_name}
          catalog={pageContext.catalog}
          home={pageContext.home}
        />

        {children}
        <Footer lang={pageContext.locale} />
      </ContextProviderComponent>
    </>
  );
};

export default HomePage;

export const IndexQuery = graphql`
  query Index($locale: String!) {
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPagePortfolio(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageService(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageGlobalReach(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageCareer(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiContactInformation(locale: { eq: $locale }) {
      Email
      Maps_link
      Street
      Company_name
      City
    }
  }
`;
