import styled from "styled-components"

//styles
import colors from "src/assets/styles/colors.js"

const CardTopBottom = styled.div`
  clip-path: polygon(
    0 0,
    calc(100% - 20rem) 0,
    100% 20rem,
    100% calc(100% - 8rem),
    calc(100% - 8rem) 100%,
    8rem 100%,
    0 calc(100% - 8rem)
  );
  border: solid 1rem ${colors.greenDark};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 19rem;
    height: 1rem;
    width: 30rem;
    background-color: ${colors.greenDark};
    transform-origin: top right;
    transform: rotate(-135deg);
    z-index: 100;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 6rem;
    right: 0rem;
    height: 1rem;
    width: 10rem;
    background-color: ${colors.greenDark};
    transform-origin: top right;
    transform: rotate(-45deg);
    z-index: 100;
  }
`

export default CardTopBottom
