const typographySizes = {
  xs: 12,
  xsh: 1.4,
  s: 16,
  sh: 1.4,
  sm: 20,
  smh: 1.2,
  m: 26,
  mh: 1.2,
  ml: 30,
  mlh: 1.1,

  l: 36,
  lh: 1.1,
  xl: 55,
  xlh: 1.1,
  xxl: 65,
  xxlh: 1.1,
}

export default typographySizes
