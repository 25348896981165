import colors from "src/assets/styles/colors.js"
import styled from "styled-components"
import mediaQuery from "src/assets/styles/mediaQuery.js"

const MainWrapper = styled.div`
  margin-left: 230rem;
  margin-right: 20rem;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20rem;
    margin-right: 20rem;
  }
`

export default MainWrapper
