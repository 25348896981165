import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";
const GlobalStyle = createGlobalStyle`


    *{
        margin: 0;
        padding: 0;
        font-family: 'GT-Eesti-Text-Light', sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        color: ${colors.white};
        border: none;
       
        @media (min-width: 1440px) {
            /* font-size: 0.0695vw; */

            }
    }


    /* html{scroll-behavior: smooth;} */
    ::selection {
        /* color: var(--rest);
        background: var(--bg); */

}

div{
    font-size: inherit;
    color: inherit;
    /* line-height: inherit !important; */
}

html {   overflow-x: hidden;
     width: 100vw; } /* Force 'html' to be viewport width */

 body{
     overflow: hidden;
     width: 100vw;
     font-size:  16rem ; 
     background: ${colors.greenDark};
     -webkit-font-smoothing: subpixel-antialiased;

     

     


 }
 img {
    display: none;
}


img[src] {
   display: block;
 }



 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
