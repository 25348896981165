exports.components = {
  "component---src-layouts-about-js": () => import("./../../../src/layouts/about.js" /* webpackChunkName: "component---src-layouts-about-js" */),
  "component---src-layouts-career-js": () => import("./../../../src/layouts/career.js" /* webpackChunkName: "component---src-layouts-career-js" */),
  "component---src-layouts-contact-js": () => import("./../../../src/layouts/contact.js" /* webpackChunkName: "component---src-layouts-contact-js" */),
  "component---src-layouts-global-network-js": () => import("./../../../src/layouts/globalNetwork.js" /* webpackChunkName: "component---src-layouts-global-network-js" */),
  "component---src-layouts-home-js": () => import("./../../../src/layouts/home.js" /* webpackChunkName: "component---src-layouts-home-js" */),
  "component---src-layouts-news-js": () => import("./../../../src/layouts/news.js" /* webpackChunkName: "component---src-layouts-news-js" */),
  "component---src-layouts-portfolio-js": () => import("./../../../src/layouts/portfolio.js" /* webpackChunkName: "component---src-layouts-portfolio-js" */),
  "component---src-layouts-service-js": () => import("./../../../src/layouts/service.js" /* webpackChunkName: "component---src-layouts-service-js" */),
  "component---src-layouts-services-js": () => import("./../../../src/layouts/services.js" /* webpackChunkName: "component---src-layouts-services-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

