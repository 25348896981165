import colors from "src/assets/styles/colors.js"
import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"

const Body = styled.p`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
  color: ${colors.white};
`

export default Body
