import styled from "styled-components"

//styles
import colors from "src/assets/styles/colors.js"

const CardMain = styled.div`
  clip-path: polygon(calc(100% - 20rem) 0, 100% 20rem, 100% 100%, 0 100%, 0 0);
  position: relative;
  border: solid 1rem ${colors.greenDark};
  background-color: ${colors.green};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 20rem;
    height: 1rem;
    width: 30rem;
    background-color: ${colors.greenDark};
    transform-origin: top right;
    transform: rotate(-135deg);
    z-index: 100;
  }
`

export default CardMain
